<template>
  <suc-select-component
    :title="$t('components.fields.employee.title')"
    :options="options"
    label="fullName"
    :form="form"
    :name="name"
  >
    <template #no-options>{{ $t("components.fields.no_options") }}</template>
  </suc-select-component>
</template>

<script>
import { SucSelectComponent, SUC_SELECT_CHANGED_EVENT, Form } from "@/components/form";

import api from "@/api/user";

export default {
  components: {
    SucSelectComponent,
  },
  props: {
    initValue: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    form: {
      type: Form,
      required: true,
    },
  },
  data() {
    return {
      options: [],
      value: null,
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const { data } = await api.getResponsibleEmployees();
      this.options = data;
      if (this.$isset(this.initValue)) {
        this.onChangeField(this.options.find((x) => x.employeeCode == this.initValue));
      }
    },
    onChangeField(option) {
      this.form[this.name] = option;
      this.$emit(SUC_SELECT_CHANGED_EVENT, this.$deepCopy(this.value));
    },
  },
};
</script>

<style scoped></style>
